import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `pizzeria`,
        `castellanza`,
        `theitalianjob`,
        `pizzeria castellanza`,
      ]}
    />
    <h1>Benvenuto!</h1>
    <p>
      Da noi troverai solo la migliore pizza, fatta con il massimo della
      passione e l’amore che compete ad un prodotto artigianale massima
      espressione dell’italianità nel mondo.
    </p>
    <p>
      Questo è <b>The Italian Job</b>{" "}
    </p>
    <TitleImg />
  </Layout>
)

export default IndexPage

const TitleImg = () => (
  <StaticQuery
    query={graphql`
      query {
        title: file(relativePath: { eq: "pics/title.png" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Img fluid={data.title.childImageSharp.fluid} />
      </div>
    )}
  />
)
